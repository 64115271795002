// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Assets/Fonts/Poppins/Poppins-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Assets/Fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Assets/Fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Assets/Fonts/Poppins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("Assets/Fonts/Poppins/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
*{
    box-sizing: border-box;
}
a{
    text-decoration: none;
    display: inline-block;
}

html.dontScroll {
    overflow: hidden;
}
.spinner_block {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: #0D0D0D;
    z-index: 99999999;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,4CAAsD;AAC1D;;AAEA;IACI,2BAA2B;IAC3B,4CAAiD;AACrD;;AAEA;IACI,8BAA8B;IAC9B,4CAAoD;AACxD;;AAEA;IACI,6BAA6B;IAC7B,4CAAmD;AACvD;AACA;IACI,+BAA+B;IAC/B,4CAAqD;AACzD;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,MAAM;IACN,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["@font-face {\n    font-family: 'Poppins-ExtraBold';\n    src: url('Assets/Fonts/Poppins/Poppins-ExtraBold.ttf');\n}\n\n@font-face {\n    font-family: 'Poppins-Bold';\n    src: url('Assets/Fonts/Poppins/Poppins-Bold.ttf');\n}\n\n@font-face {\n    font-family: 'Poppins-Regular';\n    src: url('Assets/Fonts/Poppins/Poppins-Regular.ttf');\n}\n\n@font-face {\n    font-family: 'Poppins-Medium';\n    src: url('Assets/Fonts/Poppins/Poppins-Medium.ttf');\n}\n@font-face {\n    font-family: 'Poppins-SemiBold';\n    src: url('Assets/Fonts/Poppins/Poppins-SemiBold.ttf');\n}\n*{\n    box-sizing: border-box;\n}\na{\n    text-decoration: none;\n    display: inline-block;\n}\n\nhtml.dontScroll {\n    overflow: hidden;\n}\n.spinner_block {\n    width: 100%;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    background: #0D0D0D;\n    z-index: 99999999;\n    overflow: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

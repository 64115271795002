import React, {useEffect, useState} from 'react';
import {Route, Routes} from "react-router";
import loadable, {lazy} from "@loadable/component";
import {PuffLoader} from "react-spinners";
import ScrollToTop from "./ScrollToTop";

const Routers = () => {
    let [color, setColor] = useState("#18947A");

    const Beginner = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Beginner")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Advanced = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Advanced")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Intermediate = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Intermediate")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Group1 = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Group1")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Group2 = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Group2")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Main = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Main")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
  const ContactUs = loadable(
    () =>
      new Promise((resolve, reject) =>
        setTimeout(() => resolve(import("./Pages/ContactUs/ContactUs")), 500)
      ),
    {
      fallback: <div className="spinner_block">
        <PuffLoader color={color} size={150}/>
      </div>
    }
  );
    const AboutUs = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/AboutUs/AboutUs")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Faq = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Faq/index")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Privacy = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Privacy/index")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Term = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Term/index")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    const Gallery = loadable(
        () =>
            new Promise((resolve, reject) =>
                setTimeout(() => resolve(import("./Pages/Gallery/Gallery")), 500)
            ),
        {
            fallback: <div className="spinner_block">
                <PuffLoader color={color} size={150}/>
            </div>
        }
    );
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/home" element={<Main/>}/>
                <Route path='/group_1' element={<Group1/>}/>
                <Route path='/group_2' element={<Group2/>}/>
                <Route path='/beginner' element={<Beginner/>}/>
                <Route path='/intermediate' element={<Intermediate/>}/>
                <Route path='/contact-us' element={<ContactUs/>}/>
                <Route path='/faq' element={<Faq/>}/>
                <Route path='/advanced' element={<Advanced/>}/>
                <Route path='/about' element={<AboutUs/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
                <Route path='/term' element={<Term/>}/>
                <Route path='/gallery' element={<Gallery/>}/>

                {/*<Route path='/404'  element={Main}/>*/}
                {/*<Redirect to='/404'/>*/}
            </Routes>
        </ScrollToTop>

    );
};

export default Routers;
import './App.css';

import {BrowserRouter} from "react-router-dom";
import Routers from "./routes";
import {useEffect, useState} from "react";
import {PuffLoader} from "react-spinners";

function App() {
    // console.log = console.warn = console.error = () => {};
    const [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#18947A");
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [])
    return (
        <BrowserRouter>
            {
                loading ?
                <div className="spinner_block">
                    <PuffLoader color={color} loading={loading} size={150}/>
                </div>
                    :
                    <Routers/>
            }
        </BrowserRouter>
    );
}

export default App;
